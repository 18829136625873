var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      class: ["copy__link", _vm.clicked ? "clicked" : ""],
      attrs: { href: "#", "data-link": _vm.link },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.copy.apply(null, arguments)
        },
      },
    },
    [
      _vm._v("\n    Copy link\n    "),
      _c("img", {
        staticClass: "icon copy",
        attrs: {
          src: "/assets/img/ui/icon-copy.svg",
          width: "20",
          height: "20",
          alt: "copy",
        },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "icon thumbs-up",
        attrs: {
          src: "/assets/img/ui/icon-thumbs-up.svg",
          width: "20",
          height: "20",
          alt: "copied",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <a href="#" :data-link="link" @click.prevent="copy" :class="['copy__link', clicked ? 'clicked' : '']">
        Copy link
        <img class="icon copy" src="/assets/img/ui/icon-copy.svg" width="20" height="20" alt="copy">
        <img class="icon thumbs-up" src="/assets/img/ui/icon-thumbs-up.svg" width="20" height="20" alt="copied">
    </a>
</template>
<script>
export default {
    props: {
        link: String,
        menuNameEvent: String
    },
    data() {
        return {
            clicked: false
        }
    },
    methods: {
        copy(event) {
            let text = location.protocol + '//' + location.host + event.target.dataset.link;
            navigator.clipboard.writeText(text);
            this.clicked = true;
            setTimeout(() => {
                this.clicked = false;
            }, 1000);
            setTimeout(() => {
                this.$root.$emit(this.menuNameEvent);
            }, 1200);
        }
    }
}
</script>
